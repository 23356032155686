import styled from "styled-components"
import { respondTo } from "theme/base/Mixins"
import { displays } from "theme/base/Variables"

export const ContentSendQR = styled.div`
  ${displays.flexColumn}
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  border: 1px solid #ECEDF1;

  form {
    width: 100%;
  }

  .box {
    &-error {
      background: #FFFFFF;

      p {
        margin: 0;
        padding-top: 0.5rem;
      }
    }

    &-form {
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      background: #FFFFFF;
      justify-content: center;

      &-options,
      &-fields {
        width: 100%;

        input {
          text-align: center;
        }
      }

      &-options {
        display: flex;
        align-items: center;

        & > div:first-child {
          margin-right: 1rem;
        }
      }

      &-action {
        width: 100%;
        margin-top: 1rem;

        & > button {
          width: 100%;
        }
      }
    }
  }

  &.code-check {
    padding: 0;

    .box {
      &-form {
        &-fields {
          width: 100%;
        }
      }
    }
  }

  ${respondTo.sm`
    .box-form {
      gap: 1rem;
      flex-wrap: nowrap;

      &-options {
        justify-content: center;
      }

      &-fields {
        width: 50%;
      }
      
      &-action {
        width: auto;
        margin-top: 0;
  
        & > button {
          width: inherit;
        }
      }
    }
  `}
`