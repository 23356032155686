import { Fragment, FunctionComponent, useContext } from "react"
import {mainLayoutStyle} from "overrides/theme/entel/base/components/Theme/Layout/Main/styles/MainLayout.style"
import NotificationList from "components/Commons/Notifications/NotificationList"
import { GlobalContext } from "context/context/GlobalContext"
import ErrorHandler from "overrides/theme/entel/base/components/Commons/Error/ErrorHandler"
import Header from "overrides/theme/entel/base/components/Theme/Layout/Main/Header"
import Sidebar from "overrides/theme/entel/base/components/Theme/Layout/Main/Sidebar"
import Helmet from "components/Commons/Helmet/Helmet"

const MainLayout: FunctionComponent = (props: any) => {
  const { children } = props
  const { errorHander, notification } = useContext(GlobalContext)
  const notifications = notification?.state

  if (errorHander?.state?.hasError)
    return <ErrorHandler code={errorHander.state.code} message={errorHander?.state?.message} />

  return (
    <Fragment>
      <Helmet />
      {notifications && notifications.length > 0 && (
        <NotificationList notifications={notifications} />
      )}
      <div className="root">
        <Header />
        <div className="container">
          <Sidebar />
          <main className="content">
            {children}
          </main>
        </div>
      </div>
      <style jsx>{mainLayoutStyle}</style>
    </Fragment>
  )
}

export default MainLayout
