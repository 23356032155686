import { COUNT_MAX_FILE, SUPPORTED_FORMATS, SUPPORTED_FORMATS_FULL, SUPPORTED_BULK_FILES_FORMATS } from "helpers/constHelper"
import * as Yup from "yup"

export const schemaLoginForm = Yup.object({
  user: Yup.string().required("Usuario es requerido"),
  password: Yup.string().required("Contraseña es requerida"),
})

export const schemaRememberForm = Yup.object({
  email: Yup.string()
    .email("Debe de ingresar un Correo electrónico válido")
    .required("Debe de ingresar un Correo electrónico"),
})

export const schemaNewPasswordForm = Yup.object({
  password: Yup.string().required("Nueva contraseña es requerida"),
  retryPassword: Yup.string()
    .required("Re-Ingresar nueva contraseña es requerida")
    .oneOf([Yup.ref('password')], "Las contraseñas no coinciden"),
})

export const shemaSearchForm = Yup.object({
  search: Yup.string(),
})

export const shemaFileUpladerForm = (files: any) => Yup.object({
  filesShippinGroup: Yup.mixed<any>()
    .test("required", (COUNT_MAX_FILE - files.length) === 1 ?
      "Debe seleccionar solo un archivo" :
      `Debe selecionar al menos un archivo y máximo ${COUNT_MAX_FILE - files.length}`, (value) => {
      if (!value) return false
      return value.length > 0 && value.length <= COUNT_MAX_FILE - files.length
    })
    .test("type", "Solo se acepta archivos en formato JPEG/PNG ", function (value) {
      if (!value || value.length === 0) return false
      return [...value].every(({ type }) => SUPPORTED_FORMATS.includes(type))
    })
    .test("fileSize", "Los Archivos no deben superar 5M", (value) => {
      if (!value) return false
      return [...value].every(({ size }) => {
        const fileSize = Math.round(size / 1024)
        return fileSize <= 5120
      })
    }),
})

export const shemaFileUpladerFormFull = (files: any) => Yup.object({
  filesShippinGroup: Yup.mixed<any>()
    .test("required", (COUNT_MAX_FILE - files.length) === 1 ?
      "Debe seleccionar solo un archivo" :
      `Debe selecionar al menos un archivo y máximo ${COUNT_MAX_FILE - files.length}`, (value) => {
      if (!value) return false
      return value.length > 0 && value.length <= COUNT_MAX_FILE - files.length
    })
    .test("type", "Solo se acepta archivos en formato JPEG/PNG/PDF ", function (value) {
      if (!value || value.length === 0) return false
      return [...value].every(({ type }) => SUPPORTED_FORMATS_FULL.includes(type))
    })
    .test("fileSize", "Los Archivos no deben superar 5M", (value) => {
      if (!value) return false
      return [...value].every(({ size }) => {
        const fileSize = Math.round(size / 1024)
        return fileSize <= 5120
      })
    }),
})

export const shemaBulkFilesUpladerForm = () => Yup.object({
  filesBulkOrders: Yup.mixed<any>()
    .test("required", "Debe selecionar un archivo", (value) => {
      if (!value) return false
      return value.length === 1
    })
    .test("type", "Solo se acepta archivo en formato CSV", function (value) {
      if (!value || value.length === 0) return false
      return [...value].every(({ type }) => SUPPORTED_BULK_FILES_FORMATS.includes(type))
    })
    .test("fileSize", "El archivo no debe superar 3MB", (value) => {
      if (!value) return false
      return [...value].every(({ size }) => {
        const fileSize = Math.round(size / 1024)
        return fileSize <= 3072
      })
    }),
})

export const shemaFileUpladerFormAllowEmpty = (files: any) => Yup.object({
  filesShippinGroup: Yup.mixed<any>()    
    .test("type", "Solo se acepta archivos en formato JPEG/PNG ", function (value) {
      if (!value || value.length === 0) return true
      return [...value].every(({ type }) => SUPPORTED_FORMATS.includes(type))
    })
    .test("fileSize", "Los Archivos no deben superar 5M", (value) => {
      if (!value) return true
      return [...value].every(({ size }) => {
        const fileSize = Math.round(size / 1024)
        return fileSize <= 5120
      })
    }),
})

export const schemaUserFormValidation = (edit: boolean) => {
  if (edit) {
    return Yup.object({
      name: Yup.string().required("Nombre"),
      email: Yup.string().required("Correo electrónico"),
      role: Yup.string().required("Rol"),
      old_password: Yup.string(),
      password: Yup.string(),
      rolePermissions: Yup.object(),
      groups: Yup.array(
        Yup.object({
          id: Yup.string().required(),
          mainGroup: Yup.string().required(),
          type: Yup.string(),
        })
      )
    })
  } else {
    return Yup.object({
      id: Yup.string().required("Nombre de usuario"),
      name: Yup.string().required("Nombre"),
      email: Yup.string().email("Correo electrónico").required("Correo electrónico"),
      role: Yup.string().required("Rol"),
      password: Yup.string().required("Contraseña"),
      rolePermissions: Yup.object(),
      groups: Yup.array(
        Yup.object({
          id: Yup.string(),
          mainGroup: Yup.string(),
          type: Yup.string(),
        })
      ),
    })
  }
}

export const schemaProductFormValidation = () => {
  return Yup.object({
    sku: Yup.string().required("Debe ingresar el SKU"),
    quantity: Yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .required("Debe ingresar la Cantidad")
      .moreThan(0, "Cantidad debe ser mayor que 0")
  })
}

export const schemaSendQR = Yup.object({
  email: Yup.string()
    .email("Debe de ingresar un E-Mail válido")
    .required("Debe de ingresar el E-Mail"),
})