import React, { Suspense, useContext, useEffect, useRef } from "react"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import usePickupOrder from "pages/DetailOrderScreens/Pickup/UsePickupOrder"
import PickupAction from "components/OrderDetail/PickupProductOrder/PickupAction"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import { IconSpinner } from "theme/common/Icon.styled"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { clients } from "webapp-clients/Clients"
import { AuthContext } from "context/context/AuthContext"
import useHideActionButtons from "hooks/UseHideActionButtons"
import { useForm } from "react-hook-form"
import { FilesFormType } from "types/FilesFormType"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaFileUpladerForm } from "config/ShemaForms"
import AlertStyled from "theme/common/Alert.styled"
import { IconAttachment } from "theme/common/Icon.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "theme/common/Icon.styled"
import Modal from "components/Commons/Modal/Modal"

const InfoElocker = React.lazy(() => import("components/OrderDetail/ElockerQR/InfoElocker"))

const PickupScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { hidePickupActions, updateVisibilityButtonActions } = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(shippinGroupDetail?.source?.id, shippinGroupDetail?.target?.source?.id)
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const FileUpload = client["FileUpload" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const documents = shippinGroupDetail?.custom?.documents

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(shemaFileUpladerForm([])) : undefined,
  })

  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const {
    loadingCancel,
    loadingPickup,
    handleClickCancelAction,
    handleClickPickupAction,
    hasPermissionQREntry,
    hasPermissionQRExitCancel,
    canCancelOrder,
    denyPickupAction,
    shouldBeFilePresentOnSubmit,
    handleClickPickupActionWithFiles,
  } = usePickupOrder({
    shippingGroupId,
    order,
    setFinishedShippingGroupAction,
    isElocker: elockerInfo?.isElocker,
    shippinGroupDetail,
    refModal,
  })

  const refFormFiles = useRef<any>()

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos por recoger" : undefined}
          />
          {elockerInfo && elockerInfo.isElocker && (
            <Suspense
              fallback={
                <>
                  <IconSpinner variant="big" className="content-center" />
                  <p className="padding-content">Cargando información Elocker</p>
                </>
              }
            >
              <InfoElocker
                codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo.codigoCancelacion : null}
                codigoEntrega={null}
                codigoRetiro={hasPermissionQREntry ? elockerInfo.codigoRetiro : null}
                puerta={elockerInfo.puerta}
              />
            </Suspense>
          )}
          {shouldBeFilePresentOnSubmit() && !hidePickupActions && (
            <>
              <form ref={refFormFiles} onSubmit={handleSubmit(handleClickPickupActionWithFiles)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section className="padding-content ">
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} />
                    </section>
                  </Suspense>
                )}

                {errors.filesShippinGroup && <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>}
                <ContentDetailOrderActionsStyled>
                  <ButtonStyled variant="primary" loadingButton={loadingPickup} disabled={loadingPickup} type="submit">
                    <IconCheck />
                    <span>Confirmar entrega</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              </form>
            </>
          )}

          {!finishedShippingGroupAction && !hidePickupActions && (
            <PickupAction
              onClickPickup={!denyPickupAction ? handleClickPickupAction : undefined}
              onClickCancel={canCancelOrder ? handleClickCancelAction : undefined}
              loadingCancel={loadingCancel}
              loadingPickup={loadingPickup}
              shouldBeFilePresentOnSubmit={shouldBeFilePresentOnSubmit}
            />
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList documents={documents} channel={shippinGroupDetail?.channel} orderId={order?.id} />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(PickupScreen)
