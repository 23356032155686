import { useState, useContext } from "react"
import { AuthContext } from "context/context/AuthContext"
import { useParams, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { GlobalContext } from "context/context"
import { useCancelToken } from "hooks"
import { updateShippingGroupStateCustom } from "overrides/theme/entel/base/services/ShippingGroupService"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { useEnv } from "hooks"

const useUpdateSGCustom = () => {
  const { state: { user } } = useContext(AuthContext)
  const [,env] = useEnv().multiclient.get_user_env()
  const { t } = useTranslation()
  const { shippingtype, shippingGroupId, id }: any = useParams()
  const history = useHistory()
  const { isCancel, newCancelToken } = useCancelToken()
  const { notification, errorHander } = useContext(GlobalContext)
  const [loading, set_loading] = useState(false)

  const onUpdateSGActionCustom = async (body: any, action: any) => {
    try {
      set_loading(true)
      await updateShippingGroupStateCustom(env, user?.token, body, newCancelToken())
      set_loading(false)
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Orden modificada exitosamente!",
          title: `${t(`Acción aplicada`)}: ${t(`${action.action}`)}`,
          type: "success",
        },
      })
      setTimeout(() => history.push(`/order/${shippingtype}/${action.next_page}/${shippingGroupId}/${id}`), 1000)
    } catch (error: any) {
      if (isCancel(error)) return
      if (error.response?.status === 401) return errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: JSON.stringify(error.response?.data?.message),
          title: "ERROR",
          type: "danger",
        },
      })
    }
  }

  return {
    loading,
    onUpdateSGActionCustom
  }
}

export default useUpdateSGCustom