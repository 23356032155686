import { useContext } from "react"
import { AuthContext, GlobalContext } from "context/context"
import { useCancelToken } from "hooks"
import { request_post } from "services/OSRM"

const useServicesOSRM = () => {
  const context_auth: any = useContext(AuthContext)
  const { isCancel, newCancelToken } = useCancelToken()
  const { errorHander } = useContext(GlobalContext)

  const get_user_channels = () => {
    let channels = context_auth.state.user.groups
    channels = channels.filter((channel: any) => channel.type === "Channel").map((channel: any) => channel.id)
    return channels
  }

  const get_user_sources = () => {
    let res = context_auth.state.user.groups
    res = res.filter((item: any) => item.type === "Source").map((item: any) => item.id)
    res = res.includes("ALLSOURCES") ? [] : res
    return res
  }

  const get_sg_by_id = async (status: string, sg_id: string, order_id: string, type_source: string = "source.id") => {
    let request: any = {
      from: 0,
      size: 1,
      filters: {
        channel: get_user_channels(),
        "currentStatus.tag": status.toUpperCase(),
        id: sg_id,
        orderId: order_id,
        [type_source]: get_user_sources() || []
        // "source.id": shipping_type !== "RT" ? get_user_sources() : [],
        // "target.source.id": shipping_type === "RT" ? get_user_sources() : [],
      },
    }

    try {
      return await request_post(`search/shipping_groups`, request, newCancelToken())
    } catch (e: any) {
      if (isCancel(e)) return
      errorHander?.dispatch({ hasError: true, code: e.response?.status })
    }
  }

  const get_order_by_id = async (order_id: string) => {
    let request: any = {
      from: 0,
      size: 1,
      filters: {
        channel: get_user_channels(),
        id: order_id,
      },
    }

    try {
      return await request_post(`search/orders`, request, newCancelToken())
    } catch (e: any) {
      if (isCancel(e)) return
      errorHander?.dispatch({ hasError: true, code: e.response?.status })
    }
  }

  const get_items_by_sku = async (skus: string[]) => {
    let request: any = {
      from: 0,
      size: skus.length,
      filters: {
        sku: skus,
      },
    }

    try {
      return await request_post(`search/items`, request, newCancelToken())
    } catch (e: any) {
      if (isCancel(e)) return
      errorHander?.dispatch({ hasError: true, code: e.response?.status })
    }
  }

  return {
    get_sg_by_id,
    get_order_by_id,
    get_items_by_sku
  }
}

export default useServicesOSRM
