import AppRouter from "routes/AppRouter"
import AuthProvider from "context/providers/AuthProvider"
import GlobalStyled from "theme/base/GlobalStyled"
import { ThemeProvider } from "styled-components"
import { theme } from "theme/ThemeUI"

const AppContainer = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme.default}>
        <GlobalStyled />
        <AppRouter />
      </ThemeProvider>
    </AuthProvider>
  )
}

export default AppContainer
