export default class Env {
  static _comment = "Information about the environment. @version 1.0.1 @author Naldo Duran<naldorck@gmail.com>"

  static clients = ["localhost", "wom", "privilege", "sandbox", "shiperto", "sodimac", "entel"]

  static children_clients = [
    {
      client: "logytech",
      children: this.clients[4],
    }
  ]

  static env = ["dev", "uat", "prod"]

  static defaults = {
    client: this.clients[6],
    env: this.env[0],
  }

  static sg_shipping_types = ["HD", "SP", "RT", "TR", "HDR"]

  static sg_shipping_types_wom = [this.sg_shipping_types[0], this.sg_shipping_types[1], this.sg_shipping_types[3]]

  static sg_shipping_types_privilege = [this.sg_shipping_types[0], this.sg_shipping_types[1]]

  static sg_shipping_types_shiperto = [
    this.sg_shipping_types[0],
    this.sg_shipping_types[1],
    this.sg_shipping_types[2],
    this.sg_shipping_types[4],
  ]

  static sg_actions = [
    "accept", // 0
    "packed", // 1
    "shipped", // 2
    "delivered", // 3
    "prepared", // 4
    "delivered_reject", // 5
    "picked_up", // 6
    "reject", // 7
    "cancel", // 8
    "cancel_decrease", // 9
    "cancelLogistic ", // 10
    "keep", // 11
    "discard", // 12
    "reschedule", // 13
    "courier_reject", // 14
    "delivered_return", // 15
  ]

  static sg_status = [
    "AWAITING_STORE_CONFIRMATION", // 0
    "PENDING_PACKAGE", // 1
    "AWAITING_DELIVERY", // 2
    "IN_TRANSIT", // 3
    "READY_FOR_PICKUP", // 4 // No aplica privilege
    "IN_SOURCE_PREPARATION", // 5  ? sandbox
    "RETURN_IN_REVIEW", // 6 ? sandbox: target
    "DELIVERED", // 7
    "CANCELED", // 8
    "CANCELED_DECREASE", // 9 ? sandbox
    "CLOSED", // 10
    "IN_TRANSIT_FOR_RECEPTION", // 11  ? wom: target
    "READY_FOR_PICKUP_SOURCE", // 12 privilege: target
    "IN_TRANSIT_SOURCE", // 13 privilege: target
    "IN_TRANSIT_FOR_RECEPTION_OPL", // 14  ? wom: target
    "RESCHEDULED", // 15
    "RETURNED", // 16
    "IN_RETURN_COURIER", // 17
    "IN_RETURN", // 18
  ]

  static order_status_active = [
    "RESERVED",
    "PENDING_PREPARATION",
    "READY_FOR_PICKUP",
    "IN_TRANSIT",
    "INCOMPLETE_SHIPPING",
    "INCOMPLETE_DELIVERY",
  ]

  static order_status_inactive = [
    "CANCELED",
    "CANCELED_DECREASE",
    "CANCELED_LOGISTIC",
    "CLOSED",
    "DELIVERED",
    "RESCHEDULED",
    "DELIVERED_REJECT",
  ]

  static sg_status_privilege = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[13],
    this.sg_status[12],
    this.sg_status[7],
    this.sg_status[10],
  ]

  static sg_status_wom = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[11],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[10],
    this.sg_status[8],
    this.sg_status[14],
  ]

  static sg_status_wom_hd = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[10],
    this.sg_status[8],
  ]

  static sg_status_wom_sp = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[10],
    this.sg_status[8],
  ]

  static sg_status_wom_rt = []

  static sg_status_wom_specials = [this.sg_status[11], this.sg_status[14]]

  static sg_status_shiperto_hd = [
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[7],
    this.sg_status[8],
    this.sg_status[10],
    // this.sg_status[15],
  ]

  static sg_status_shiperto_hdr = [
    this.sg_status[16],
    this.sg_status[17],
    this.sg_status[15],
    this.sg_status[8],
  ]

  static sg_status_shiperto_sp = [
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[8],
    this.sg_status[10],
  ]

  static sg_status_shiperto_rt = [
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[6],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_sandbox = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[5],
    this.sg_status[6],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[16],
    this.sg_status[18],
    this.sg_status[10],
    this.sg_status[8],

  ]

  static sg_status_sandbox_hd = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[18],
    this.sg_status[16],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_sandbox_sp = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[5],
    this.sg_status[4],
    this.sg_status[8],
    this.sg_status[10],
  ]

  static sg_status_sandbox_rt = [
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[6],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_sandbox_tr = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_entel = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[5],
    this.sg_status[6],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[18],
    this.sg_status[16],
    this.sg_status[10],
    this.sg_status[8],
  ]

  // Temporal: requirement should be improved, this should be controlled by shippingtypes
  static sg_shippingtype_rt_status = [
    this.sg_status[6],
    this.sg_status[16],
    this.sg_status[18]
  ]

  static sg_status_action: any = {
    [this.sg_status[0]]: [
      {
        action: this.sg_actions[0],
        next_page: this.sg_status[1].toLowerCase(),
        label: "Confirmar",
      },
    ],
    [this.sg_status[5]]: [
      {
        action: this.sg_actions[4],
        next_page: "pickup", // fix this route
        label: "Confirmar",
      },
    ],
    [this.sg_status[6]]: [
      {
        action: this.sg_actions[11],
        next_page: this.sg_status[7].toLowerCase(),
        label: "Conservar",
      },
      {
        action: this.sg_actions[12],
        next_page: this.sg_status[7].toLowerCase(),
        label: "Despachar",
      },
    ],
  }

  static sg_status_action_shiperto_hd = {
    [this.sg_status[1]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[1],
        next_page: this.sg_status[2].toLowerCase(),
      },
    ],
    [this.sg_status[2]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[2],
        next_page: this.sg_status[3].toLowerCase(),
      },
    ],
    [this.sg_status[3]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[3],
        next_page: this.sg_status[7].toLowerCase(),
      },
      {
        action: this.sg_actions[14],
        next_page: this.sg_status[17].toLowerCase(),
      },
    ],
    [this.sg_status[7]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
    ],
    [this.sg_status[8]]: [],
    [this.sg_status[10]]: [],
    // [this.sg_status[15]]: [
    //   {
    //     action: this.sg_actions[8],
    //     next_page: this.sg_status[8].toLowerCase(),
    //   },
    // ],
  }

  static sg_status_action_shiperto_hdr = {
    [this.sg_status[15]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
    ],
    [this.sg_status[16]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[13],
        next_page: this.sg_status[15].toLowerCase(),
      },
    ],
    [this.sg_status[17]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[15],
        next_page: this.sg_status[16].toLowerCase(),
      },
    ],
  }

  static sg_status_action_shiperto_sp = {
    [this.sg_status[1]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[1],
        next_page: this.sg_status[4].toLowerCase(),
        alt_next_page: this.sg_status[2].toLowerCase(),
      },
    ],
    [this.sg_status[2]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[2],
        next_page: this.sg_status[3].toLowerCase(),
      },
    ],
    [this.sg_status[3]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[3],
        next_page: this.sg_status[4].toLowerCase(),
      },
    ],
    [this.sg_status[4]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[6],
        next_page: this.sg_status[10].toLowerCase(),
      },
    ],
    [this.sg_status[7]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
    ],
    [this.sg_status[8]]: [],
    [this.sg_status[10]]: [],
  }

  static sg_status_action_shiperto_rt = {
    [this.sg_status[2]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[2],
        next_page: this.sg_status[3].toLowerCase(),
      },
    ],
    [this.sg_status[3]]: [
      // {
      //   action: this.sg_actions[8],
      //   next_page: this.sg_status[8].toLowerCase()
      // },
      {
        action: this.sg_actions[3],
        next_page: this.sg_status[6].toLowerCase(),
      },
    ],
    [this.sg_status[6]]: [
      // {
      //   action: this.sg_actions[8],
      //   next_page: this.sg_status[8].toLowerCase()
      // },
      {
        action: this.sg_actions[12],
        next_page: this.sg_status[7].toLowerCase(),
      },
      {
        action: this.sg_actions[11],
        next_page: this.sg_status[7].toLowerCase(),
      },
    ],
    [this.sg_status[7]]: [
      // {
      //   action: this.sg_actions[8],
      //   next_page: this.sg_status[8].toLowerCase()
      // }
    ],
    [this.sg_status[8]]: [],
  }

  static theme: any = {
    core: {
      id: "core",
      sg: {
        shipping_types: this.sg_shipping_types,
        default: {
          status: this.sg_status,
          actions: this.sg_actions,
        },
      },
    },
    wom: {
      id: this.clients[1],
      sg: {
        shipping_types: this.sg_shipping_types_wom,
        default: {
          status: this.sg_status_wom,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_wom_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_wom_sp,
        },
        [this.sg_shipping_types[2]]: {
          status: this.sg_status_wom_rt,
        },
      },
    },
    privilege: {
      id: this.clients[2],
      sg: {
        shipping_types: this.sg_shipping_types_privilege,
        default: {
          status: this.sg_status_privilege,
        },
      },
    },
    sandbox: {
      id: this.clients[3],
      sg: {
        default: {
          status: this.sg_status_sandbox,
          status_actions: this.sg_status_action,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_sandbox_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_sandbox_sp,
        },
        [this.sg_shipping_types[2]]: {
          status: this.sg_status_sandbox_rt,
        },
        [this.sg_shipping_types[3]]: {
          status: this.sg_status_sandbox_tr,
        },
      },
    },
    shiperto: {
      id: this.clients[4],
      sg: {
        shipping_types: this.sg_shipping_types_shiperto,
        default: {
          status_actions: this.sg_status_action_shiperto_hd,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_shiperto_hd,
          status_actions: this.sg_status_action_shiperto_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_shiperto_sp,
          status_actions: this.sg_status_action_shiperto_sp,
        },
        [this.sg_shipping_types[2]]: {
          status: this.sg_status_shiperto_rt,
          status_actions: this.sg_status_action_shiperto_rt,
        },
        [this.sg_shipping_types[4]]: {
          status: this.sg_status_shiperto_hdr,
          status_actions: this.sg_status_action_shiperto_hdr,
        },
      },
    },
    sodimac: {
      id: this.clients[5],
    },
    entel: {
      id: this.clients[6],
      sg: {
        default: {
          status: this.sg_status_sandbox,
          status_actions: this.sg_status_action,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_sandbox_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_sandbox_sp,
        },
        [this.sg_shipping_types[2]]: {
          status: this.sg_status_sandbox_rt,
        },
        [this.sg_shipping_types[3]]: {
          status: this.sg_status_sandbox_tr,
        },
      },
    },
  }
}
