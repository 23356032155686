import { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import SearchForm from "overrides/theme/entel/base/components/SearchForm/SearchForm"
import { useOrderGrouped } from "overrides/theme/entel/base/hooks"
import { DashboardScreenStyle } from "overrides/theme/entel/base/pages/HomeScreens/HomeScreen/styles/DashboardScreen.style"
import DashboardContent from "overrides/theme/entel/base/pages/HomeScreens/HomeScreen/DashboardContent"
import DashboardAside from "overrides/theme/entel/base/pages/HomeScreens/HomeScreen/DashboardAside"
import { useEnv } from "hooks"

const DashBordScreen = (props: any) => {
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const history = useHistory()
  const { order_group_formatted } = useOrderGrouped()
  const { sg, permissions } = useEnv()
  const [data, set_data]: any = useState(null)
  const [hd_return_data, set_hd_return_data]: any = useState(null)
  const [loading, set_loading] = useState(true)
  const sg_list_all: any = sg.get_sg_shippingtype_list_all()

  const handleClickSearch = (e: any) => history.push(`/search/${e.optionFilter}/${e.query}`)

  useEffect(() => {
    displayHeaderRouteName.dispatch("Actividades y Despachos")
    const fetch_data = async () => {
      set_loading(true)
      const response = await order_group_formatted(
        ["HD"],
        sg_list_all["HD"]["status"],
        "target.source.id"
      )
      set_data(response)

      const response_hd_return = await order_group_formatted(
        ["HD"],
        sg_list_all["HD"]["status"],
        "target.source.id"
      )
      set_hd_return_data(response_hd_return)

      set_loading(false)
    }
    fetch_data()
    //eslint-disable-next-line
  }, [])

  const select_options_main = [
    {
      value: "Tipo de envío",
      id: "SP,HD",
    },
    {
      value: "HD",
      id: "HD",
    },
    {
      value: "SP",
      id: "SP",
    },
  ]

  const template_dashboard = (
    <section className="dashboard">
      {permissions.get_permission("dashboard", "block-reports") && <DashboardAside />}
      <article className="dashboard__content">
        {permissions.get_permission("dashboard", "block-order-activity") && (
          <DashboardContent
            data={null}
            class_modifier={"main"}
            title={"Resumen de actividades - Despachos"}
            select_options={select_options_main}
          />
        )}
        {permissions.get_permission("dashboard", "block-order-return-activity") && (
          <DashboardContent data={hd_return_data} class_modifier={"home_delivery_return"} title={"Despachos en retorno"} />
        )}
        {permissions.get_permission("dashboard", "block-devolutions") && (
          <DashboardContent data={data} class_modifier={"devolutions"} title={"Devoluciones"} />
        )}
      </article>
    </section>
  )

  return (
    <>
      {loading && <Loading defaultOpened={true} />}
      {permissions.get_permission("global", "block-search-dashboard") && (
        <SearchForm onClickSearch={handleClickSearch} />
      )}
      {template_dashboard}
      <style jsx>{DashboardScreenStyle}</style>
    </>
  )
}

export default DashBordScreen
