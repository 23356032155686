import { Fragment, FunctionComponent, useContext, useEffect, useRef, useState } from "react"
import IPage from "interfaces/IPage"
import UseManifest from "hooks/UseManifest"
import { ContentStyledManifest } from "theme/common/Manifest.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import { formatAdvanceDate } from "helpers/FormatDateHelper"
import Skeleton from "react-loading-skeleton"
import { downloadManifest } from "helpers/pdfGenerationHelper"
import OrderFilterDate from "components/Core/Order/FilterDate/OrderFilterDate"
import BackBotton from "components/Commons/BackBotton/BackBotton"
import { IRangeExtend } from "interfaces/IContentCelendar"
import SkeletonGrid from "components/Commons/Skeleton/SkeletonGrid"
import AlertStyled from "theme/common/Alert.styled"
import { IconUpLoad, IconFile, IconTrash } from "theme/common/Icon.styled"
import Modal from "components/Commons/Modal/Modal"
import WindowModal from "components/Commons/WindowModal/WindowModal"
import { AuthContext } from "context/context/AuthContext"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"

const ManifestScreen: FunctionComponent<IPage> = (props: any) => {

  const { list, loading, initial, getFileByID, searchManifest, loadingUpLoad, handleUpload, handleDeleteFile } = UseManifest()
  const [rolSuperRoot, setRolSuperRoot] = useState(false)
  const [rangeDate, setRangeDate] = useState<IRangeExtend>()
  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const {
    state: { user },
  } = useContext(AuthContext)

  const onSelectedRangeDate = (range?: IRangeExtend) => {
    setRangeDate(range)
  }

  const validFileType = (file: any) => {
    return file.type.includes('image/') || file.type === 'application/pdf';
  }

  const handleFileChange = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && validFileType(e.target.files[0])) {
      handleUpload(id, e.target.files[0])
    }else {
      refModal.current?.open()
    }
  }

  useEffect( () => {
    rangeDate && searchManifest(rangeDate)
  },
  //eslint-disable-next-line
  [rangeDate])

  useEffect(() => {
    setRolSuperRoot(user?.role === ResourceRoleEnum["webapp-super-root"])
  },
  //eslint-disable-next-line
  [user])

  return (
    <>
      <BackBotton>
        <OrderFilterDate onSelectedRangeDate={onSelectedRangeDate} />
      </BackBotton>

      {loading ? (
        <ContentStyledManifest>
          <div className="box-loading">
            <SkeletonGrid count={3} />
          </div>
        </ContentStyledManifest>
      ) : (
        <ContentStyledManifest>
          {initial ? (
            <div className="box-message">
              <AlertStyled variant="default">
                <p>Debe filtrar por fechas para mostrar los manifiestos.</p>
              </AlertStyled>
            </div>
          ) : (
            <div className="box-table">
              {list.length ? (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Fecha</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item: any) => (
                      <tr key={item._id}>
                        <td>{item._id}</td>
                        <td>{formatAdvanceDate(item.date, "yyyy-MM-dd HH:mm:ss") || <Skeleton width={200} />}</td>
                        <td><ButtonStyled variant="primary" fullSize={true} onClick={() => downloadManifest(item)}><span>Descargar</span></ButtonStyled></td>
                        <td className="button-actions">
                          {loadingUpLoad ? (
                            <div className="loader"></div>
                          ) : (
                            <Fragment>
                              {!item?.hasFile &&
                                <div className="button-file">
                                  <label className='primary' htmlFor="file"><IconUpLoad /></label>
                                  <input id="file" type="file" onChange={(e) => { handleFileChange(item._id, e) }} className="input-file" />
                                </div>
                              }
                              {item?.hasFile &&
                                <Fragment>
                                  <ButtonStyled variant="primary" fullSize={true} onClick={() => getFileByID(item._id)}><IconFile /></ButtonStyled>
                                  {rolSuperRoot &&
                                    <ButtonStyled variant="primary" fullSize={true} onClick={() => handleDeleteFile(item._id)}><IconTrash /></ButtonStyled>
                                  }
                                </Fragment>
                              }
                            </Fragment>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="box-message">
                  <AlertStyled variant="warning">
                    <p>No se encontraron manifiestos en el rango de fechas selecionado.</p>
                  </AlertStyled>
                </div>
              )}
            </div>
          )}
        </ContentStyledManifest>
      )}
      
      <Modal ref={refModal}>
        <WindowModal
          title="ERROR"
          description="Solo se acepta archivos en formato JPEG/JPG/PNG/PDF"
          handleConfirm={() => {
            refModal.current?.close()
          }}
          showCancel={false}
        />
      </Modal>
    </>
  )
}

export default ManifestScreen