import { useContext, useRef } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Env } from "classes"
import { GlobalContext } from "context/context/GlobalContext"
import OrderInfoPrint from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderInfoPrint"
import OrderDetailProductList from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailProductList"
import OrderDetailTraces from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailTraces"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import { formatDate } from "helpers/FormatDateHelper"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { useEnv } from "hooks"
import Modal from "components/Commons/Modal/Modal"
import OrderDetailModalAction from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailModalAction"

const OrderDetailsBtnAction = (props: any) => {
  const { action, index, event_sg_action, t, permissions, status } = props
  const cond = permissions.get_permission(`status_${status}`, `action-${action.action}`)
  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const template = (
    <>
      <Modal ref={refModal}>
        <OrderDetailModalAction
          action={action}
          confirmAction={() => event_sg_action(action)}
          close={() => refModal.current?.close()}
        />
      </Modal>
      <button
        key={index}
        className={`order__main__detail__action__action order__main__detail__action__${action.action}`}
        onClick={() => refModal.current?.open()}
      >
        {t(`${action.action}`)}
      </button>
    </>
  )

  return cond && template
}

const OrderDetails = (props: any) => {
  const { t } = useTranslation()
  const statusses: any = translatesObj
  const { data, set_loading } = props
  const { shippingtype, type, shippingGroupId, id }: any = useParams()
  const history = useHistory()
  const { permissions } = useEnv()
  const { notification, errorHander } = useContext(GlobalContext)
  const actions = Env.theme.shiperto.sg[shippingtype.toUpperCase()].status_actions[type.toUpperCase()]

  const shiperto_shipping_types = Env.theme.shiperto.sg.shipping_types
  const shiperto_statusses_hdr = Env.theme.shiperto.sg[shiperto_shipping_types[3]].status

  const event_sg_action = async (event: any) => {
    const action = event.action
    let shipping_type_action = shippingtype

    if (shiperto_statusses_hdr.includes(event.next_page.toUpperCase()) && shippingtype === shiperto_shipping_types[0].toLowerCase()) {
      shipping_type_action = shiperto_shipping_types[3].toLowerCase()
    }

    try {
      set_loading(true)
      await executeShipingGroupAction({ shippingGroupId, action })
      set_loading(false)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Orden modificada exitosamente!",
          title: `${t(`Acción aplicada`)}: ${t(`${action}`)}`,
          type: "success",
        },
      })
      setTimeout(() => history.push(`/order/${shipping_type_action}/${event.next_page}/${shippingGroupId}/${id}`), 1000)
    } catch (e: any) {
      errorHander?.dispatch({ hasError: true, code: 500 })
    }
  }

  const currentStatusTag = data._sg?.currentStatus?.tag?.toUpperCase()

  const template = (
    <section className="page__order">
      <article className="order__main">
        {permissions.get_permission("details", "block-info") && (
          <section className="order__main__detail">
            <h2>{t(`Orden # ${data._sg?.id}`)}</h2>

            <article className="order__main__detail__summary">
              {data._sg?.source?.name && (
                <h3>
                  {t("Source")} {data._sg?.source?.name}
                </h3>
              )}

              {data._sg?.source?.shippingAddress && (
                <>
                  <h3>
                    {t("Cliente")} {data._sg?.source?.shippingAddress?.contact?.name}
                  </h3>
                  {data._sg?.source?.shippingAddress?.contact?.id && (
                    <div>
                      <p>{t("Documento")}:</p>
                      <p>
                        <strong>{data._sg?.source?.shippingAddress?.contact?.id}</strong>
                      </p>
                    </div>
                  )}
                  {Object.values(data._sg?.source?.shippingAddress).map((info: any, _index: any) => {
                    if (typeof info === "string") return <p key={_index}>{info}</p>
                    return null
                  })}
                  {data._sg?.target?.source?.id && (
                    <div>
                      <p>{t("Destino")}:</p>
                      <p>
                        <strong>{data._sg?.target?.source?.id}</strong>
                      </p>
                    </div>
                  )}
                </>
              )}

              {data._sg?.target?.customer?.name && <h4>{data._sg?.target?.customer?.name}</h4>}

              {data._sg?.target?.source?.id && data._sg?.shippingType === "SP" && (
                <div>
                  <p>{t("Punto de retiro")}:</p>
                  <p>
                    <strong>{data._sg?.target?.source?.id}</strong>
                  </p>
                </div>
              )}

              {data._sg?.target?.customer?.id && (
                <div>
                  <p>{t("Documento")}:</p>
                  <p>
                    <strong>{data._sg?.target?.customer?.id}</strong>
                  </p>
                </div>
              )}

              {data._sg?.target?.customer?.email && (
                <div>
                  <p>{t("Email")}:</p>
                  <p>
                    <strong>{data._sg?.target?.customer?.email}</strong>
                  </p>
                </div>
              )}

              <div>
                <p>{t("Estado actual")}:</p>
                <p>
                  <strong>{t(statusses[currentStatusTag]?.title)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Fecha de estado actual")}:</p>
                <p>
                  <strong>{formatDate(data._sg?.currentStatus?.date)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Tipo de de despacho")}:</p>
                <p>
                  <strong>{t(`${data._sg?.shippingType}`)}</strong>
                </p>
              </div>
              {data._order?.deliveryType && (
                <div>
                  <p>{t("Tipo de entrega")}:</p>
                  <p>
                    <strong>{t(`${data._order?.deliveryType}`)}</strong>
                  </p>
                </div>
              )}
              <div>
                <p>{t("ID canal de ventas")}:</p>
                <p>
                  <strong>{data._sg?.salesChannelId}</strong>
                </p>
              </div>
              <div className="order__main__detail__action">
                {!!actions &&
                  actions.map((action: any, index: number) => (
                    <OrderDetailsBtnAction
                      action={action}
                      index={index}
                      t={t}
                      permissions={permissions}
                      event_sg_action={event_sg_action}
                      status={type}
                      key={index}
                    />
                  ))}
              </div>
            </article>
          </section>
        )}
        {permissions.get_permission("details", "block-tag") && <OrderInfoPrint data={data} />}
      </article>

      {permissions.get_permission("details", "block-traces") && <OrderDetailTraces data={data} />}

      {permissions.get_permission("details", "block-products") && <OrderDetailProductList data={data} />}
    </section>
  )

  return template
}

export default OrderDetails
