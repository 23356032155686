import i18n from "i18next"
import translationDefault from "./default/translation.json"
import translationWom from "./wom/translation.json"
import translationPrivilige from "./privilege/translation.json"
import translationEntel from "./entel/translation.json"
import translationShiperto from "overrides/theme/shiperto/base/translates/translation.json"
import { initReactI18next } from "react-i18next"
import { Env, MultiClient } from "classes"

let [_client] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

export const resources = {
  default: {
    translation: translationDefault,
  },
  sandbox: {
    translation: translationDefault,
  }, 
  shiperto: {
    translation: translationShiperto,
  },
  wom: {
    translation: translationWom,
  },
  privilege: {
    translation: translationPrivilige,
  },
  entel: {
    translation: translationEntel,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "default",
  lng: _client,
})

export default i18n
