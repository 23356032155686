import Input from "components/Commons/Input"
import { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import AlertStyled from "theme/common/Alert.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import { ContentSendQR } from "./SendQR.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import { notificationPickupCode } from "services/ListenerServices"
import Logger from "classes/Logger"
import useCancelToken from "hooks/UseCancelToken"
import WindowModal from "components/Commons/WindowModal/WindowModal"
import Modal from "components/Commons/Modal/Modal"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { ISendQR } from "interfaces/ISendQR"
import { schemaSendQR } from "config/ShemaForms"
import { yupResolver } from "@hookform/resolvers/yup"

const SendQR = ({ orderId, customer, shippingGroupId, onProcessCode, shippingType }: ISendQR) => {
  const [loading, setLoading] = useState(false)

  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const { isCancel, newCancelToken } = useCancelToken()

  const preLoadedInfo = {
    email: customer?.email ? customer?.email : "",
  }

  const [modal, setModal] = useState({
    title: "",
    description: "",
  })

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: preLoadedInfo,
    resolver: yupResolver(schemaSendQR),
  })

  const submitQR = async () => {

    try {
      setModal((modal) => ({ ...modal, title: "", description: "" }))
      setLoading(true)

      const body = {
        orderId: orderId,
        shippingGroupId: shippingGroupId,
        type: "qr",
        receiver: getValues("email"),
      }

      const {
        data: { code, message },
      } = await notificationPickupCode(body, newCancelToken())

      if (code === TransactionCodeEnum.ok) {
        setLoading(false)

        setModal((modal) => ({
          ...modal,
          title: "QR de Retiro",
          description: "El QR de retiro se envió correctamente",
        }))

        onProcessCode({ code: message?.codigoRetiro })

        refModal.current?.open()
      } else {
        setLoading(false)

        setModal((modal) => ({
          ...modal,
          title: "QR de Retiro",
          description: "Ha ocurrido un error inesperado al enviar el código. Intente nuevamente",
        }))

        refModal.current?.open()
      }
    } catch (error: any) {
      setLoading(false)

      Logger.error(error)
      if (isCancel(error)) return

      setModal((modal) => ({
        ...modal,
        title: `Error: ${error.response?.status || TransactionCodeEnum.server}`,
        description: "Ha ocurrido un error inesperado al enviar el código. Intente nuevamente",
      }))

      refModal.current?.open()
    }
  }

  return (
    <>
      <Modal ref={refModal}>
        <WindowModal
          title={modal.title}
          description={modal.description}
          handleConfirm={() => {
            refModal.current?.close()
          }}
          showCancel={false}
        />
      </Modal>

      <ContentSendQR>
        <AlertStyled variant="info">
          <p>Enviar QR de Retiro</p>
        </AlertStyled>
        <form onSubmit={handleSubmit(submitQR)}>
          {errors.email && (
            <div className="box-error">
              <ErrorFieldForm>{errors.email.message}</ErrorFieldForm>
            </div>
          )}

          <div className="box-form">
            <div className="box-form-fields">
              <Input
                {...register("email")}
                type="text"
                placeHolder="E-Mail"
                className="field-input"
              />
            </div>
            <div className="box-form-action">
              <ButtonStyled
                variant="primary"
                loadingButton={loading}
                disabled={loading}
                type="submit"
              >
                <span>Enviar</span>
              </ButtonStyled>
            </div>
          </div>
        </form>
      </ContentSendQR>
    </>
  )
}

export default SendQR
