import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router"
import Logger from "classes/Logger"
import { OderGroupingType } from "types/OrderType"
import { IOrderShippinggroup } from "interfaces/IOrder"
import { getValueShippinggroupStatusEnum, groupBy } from "helpers/UtilsHelper"
import { formatDate } from "helpers/FormatDateHelper"
import configEnv from "config/configEnv"
import { STORAGE_SORT_ORDER, TODAY, YERTERDAY } from "helpers/constHelper"
import useCancelToken from "hooks/UseCancelToken"
import { IRangeExtend } from "interfaces/IContentCelendar"
import { ILocationState } from "interfaces/IMenu"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { AuthContext } from "context/context/AuthContext"
import { GlobalContext } from "context/context/GlobalContext"
import { typeVariantObject } from "types/CommonsTypes"
import { OptionsFilterEnum } from "enums/OptionsFilterEnum"
import { SortOrderEnum } from "enums/OrderEnum"
import useLocalStorage from "hooks/useLocalStorage"
import useEnv from "hooks/core/useEnv"
import Env from "classes/Env"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"
import { request_post } from "services/OSRM"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"
//import { updateShippingGroup } from "services/ShippingGroupService"

const INITIAL_PAGE = 0

const useGetSgsByStatusWOM = (
  status: string,
  filterOrder: typeVariantObject = { [OptionsFilterEnum.query]: undefined },
) => {
  const [ordersGroup, setOrdersGroup] = useState<OderGroupingType>({})
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(INITIAL_PAGE)
  const [maxPage, setMaxPage] = useState(0)
  const [rangeDate, setRangeDate] = useState<IRangeExtend>()
  const [search, setSearch] = useState<typeVariantObject>(filterOrder)
  const [sort, setSort] = useLocalStorage(STORAGE_SORT_ORDER, SortOrderEnum.desc)
  const [customFilters, setCustomFilters] = useState<typeVariantObject | null>(null)

  const {
    state: { user },
  } = useContext(AuthContext)

  const { sg } = useEnv()

  const { errorHander } = useContext(GlobalContext)

  const { isCancel, newCancelToken } = useCancelToken()

  const location = useLocation<ILocationState>()

  const roleSellerOrSup = () => {
    return user?.role === ResourceRoleEnum["webapp-seller-source"] || user?.role === ResourceRoleEnum["webapp-sup-seller-source"]
  }

  //Make the request to build the current list of SGS
  useEffect(() => {

    const getOrdersGroupByDate = async () => {
      let statusValue: EShippinggroupStatus = getValueShippinggroupStatusEnum(status)
      if (!user?.currentSources) {
        Logger.error("Not found sources")
        return
      }

      const isSellerOrSup: boolean = roleSellerOrSup()

      const requestBody: any = {
        size: statusValue === EShippinggroupStatus.delivery ? 50 : configEnv.maxRecords,
        from: page * configEnv.maxRecords,
        filters: {
          shippingType: sg.get_shippingtype_list(),
          channel: get_user_channels(),
          orderCreationDate: rangeDate
          ? {
              from: `${rangeDate.startDate && formatDate(rangeDate.startDate, "yyyy-MM-dd")}T00:00:00`,
              to: `${rangeDate.endDate && formatDate(rangeDate.endDate, "yyyy-MM-dd")}T23:59:59`,
            }
          : undefined,
        },

        sortBy: {
          "status.date": {
            order: sort,
            mode: "min",
          },
        },
        fields: [
          "orderId",
          "orderCreationDate",
          "id",
          "channel",
          "shippingType",
          "salesChannelId",
          "source.id",
          "source.name",
          "target.id",
          "target.name",
          "target.customer",
          "currentStatus",
          "custom.infoElocker",
          "custom.consolidation",
          "custom.retiroMeson",
          "custom.deliveryType",
          "target.source.id",
          "custom.courierDeliveryInfo",
          "custom.infoEntrega",
          "custom.courier",
          "custom.portabilidad",
          //"custom.is_search_approval",
          "status",
          "trace",
        ],
      }

      let couriers = get_user_courier()

      if (search.hasOwnProperty(OptionsFilterEnum.query)) {

        requestBody.filters = {
          ...requestBody.filters,
          orderId: couriers?.length ? undefined : search[OptionsFilterEnum.query],
          /*id: couriers?.length ? search[OptionsFilterEnum.query] : undefined,*/
        }

        if(/^SG|sg/.test(search[OptionsFilterEnum.query])) {
          requestBody.filters = {
            ...requestBody.filters,
            id: search[OptionsFilterEnum.query],
            orderId: undefined,
          }
        }
      } else {
        requestBody.filters = {
          ...requestBody.filters,
          ...search,
        }

        if (search.hasOwnProperty(OptionsFilterEnum["source.items.custom.series"])) {
          requestBody.filters = {
            ...requestBody.filters,
            "source.items.custom.series": [`*${(search[OptionsFilterEnum["source.items.custom.series"]])}*`],
          }
        }
        
      }

      /// custom filters
      if (customFilters) {
        requestBody.filters = {
          ...requestBody.filters,
          ...customFilters,
        }
      }

      /*if (status === "in_transit_for_reception") {
        let query_is_empty = Object.entries(search)
        query_is_empty = query_is_empty[0][1]

        if (typeof query_is_empty === "undefined") {
          requestBody.filters = {
            ...requestBody.filters,
            "custom.is_search_approval": true,
          }
        }
      }*/

      // TODO: Make interface
      let response: any = {}
      let responseRecords = []
      let responseError: any = null
      let orders: any

      // Block render page
      setLoading(true)

      try {
        requestBody.filters = {
          ...requestBody.filters,
          "source.id": user.currentSources,
        }

        // Custom status
        if (location.state?.hasOwnProperty("aliasStatus") && location.state?.hasOwnProperty("parentStatus")) {
          statusValue = location.state.parentStatus || statusValue

          let aliasStatus = location.state.aliasStatus

          if (aliasStatus === EShippinggroupStatus.in_transit_for_reception) {
            requestBody.filters = {
              ...requestBody.filters,
              shippingType: ["SP"],
              "source.id": ["WWH"],
              "target.source.id": user.currentSources,
            }
          }

          if (aliasStatus === EShippinggroupStatus.in_transit_for_reception_opl) {
            requestBody.filters = {
              ...requestBody.filters,
              shippingType: ["HD", "SP"],
              "source.id": ["WWH"],
            }
          }
        }

        requestBody.filters = {
          ...requestBody.filters,
          "currentStatus.tag": statusValue !== EShippinggroupStatus.all ? statusValue : undefined,
        }

        if (couriers?.length) {
          requestBody.entities = {
            parent: {
              entity: "shipping_groups",
              filters: requestBody.filters,
              fields: requestBody.fields,
            },
            child: {
              entity: "orders",
              should: {
                "custom.couriers": couriers,
                "additionalInformation.couriers": couriers,
              },
              filters: {
                id: search.hasOwnProperty(OptionsFilterEnum.query) && !/^SG|sg/.test(search[OptionsFilterEnum.query]) ? search[OptionsFilterEnum.query] : undefined,
              },
              fields: ["id"],
              filterInParentEntity: "orderId",
            },
          }

          //filter ORDERS
          if (statusValue !== EShippinggroupStatus.all) {
            requestBody.entities.child.filters = {
              ...requestBody.entities.child.filters,
              "currentStatus.tag": Env.order_status_active,
            }

            if (Env.order_status_inactive.includes(statusValue.toString())) {
              requestBody.entities.child.filters = {
                ...requestBody.entities.child.filters,
                "currentStatus.tag": Env.order_status_inactive,
              }
            }
          }else {
            requestBody.entities.parent.filters = {
              ...requestBody.entities.parent.filters,
              "currentStatus.tag": [EShippinggroupStatus.transit, EShippinggroupStatus.pickup, EShippinggroupStatus.delivered, EShippinggroupStatus.closed],
            }
          }

          /*
          if (statusValue === EShippinggroupStatus.delivered) {
            requestBody.entities.parent.filters = {
              ...requestBody.entities.parent.filters,
              "currentStatus.tag": [EShippinggroupStatus.delivered, EShippinggroupStatus.pickup, EShippinggroupStatus.closed],
            }
          }
          */

          // remove atributes OSRM
          requestBody.filters = undefined
          requestBody.fields = undefined
        }

        if (requestBody.entities !== undefined)
          response = await request_post(`compound_search`, requestBody, newCancelToken())
        else response = await request_post(`search/shipping_groups`, requestBody, newCancelToken())

        response = response.data.message
        responseRecords = response.records

        /*if (status === "in_transit_for_reception") {
          for (const item of responseRecords) {
            if (typeof item.custom?.is_search_approval === "undefined") {
              await updateShippingGroup(
                {
                  shippingGroupId: item.id,
                  custom: {
                    is_search_approval: true,
                  },
                },
                newCancelToken(),
              )
            }
          }
        }*/

        if (responseRecords.length <= 0) {
          if (isSellerOrSup && statusValue === EShippinggroupStatus.all) {
            requestBody.filters = {
              ...requestBody.filters,
              "currentStatus.tag": EShippinggroupStatus.transit,
              shippingType: ["SP"],
              "source.id": ["WWH"],
              "target.source.id": user.currentSources,
            }
  
            response = await request_post(`search/shipping_groups`, requestBody, newCancelToken())
  
            response = response.data.message
            responseRecords = response.records
  
            if (responseRecords.length <= 0) {
              setLoading(false)
              setOrdersGroup(null)
              return
            }
          } else {
            setLoading(false)
            setOrdersGroup(null)
            return
          }
        }
      } catch (error: any) {
        if (isCancel(error)) return
        responseError = error.response?.status
        Logger.error("No results with source.id filter active:", user.currentSources.toString())
      }

      // If doesn't exist orders, return error message
      if (!!responseError) {
        if (responseError === TransactionCodeEnum.notFound) {
          setLoading(false)
          setOrdersGroup(null)
          return
        }
        errorHander?.dispatch({ hasError: true, code: responseError })
      }

      // Custom status
      if ((statusValue === EShippinggroupStatus.all) && (!couriers?.length)) {
        responseRecords = responseRecords.map((_sg: IOrderShippinggroup) => {
          const reception: boolean =
            _sg.currentStatus.tag === EShippinggroupStatus.transit &&
            _sg.shippingType === "SP" &&
            _sg.source.id === "WWH"

          const reception_opl: any =
            _sg.currentStatus.tag === EShippinggroupStatus.transit &&
            (_sg.shippingType === "HD" || _sg.shippingType === "SP") &&
            _sg.source.id === "WWH" &&
            couriers?.length

          if (reception) {
            _sg.currentStatus.aliasStatus = EShippinggroupStatus.in_transit_for_reception
          }

          if (reception_opl) {
            _sg.currentStatus.aliasStatus = EShippinggroupStatus.in_transit_for_reception_opl
          }

          return _sg
        })
      }

      orders = groupBy("orderCreationDateFormat", formatDateOrders(responseRecords))
      setOrdersGroup(orders)
      setMaxPage(Math.ceil(response.pages))
      setLoading(false)
    }

    getOrdersGroupByDate()

    // eslint-disable-next-line
  }, [status, page, rangeDate, search, sort, customFilters])

  const get_user_channels = () => {
    let channels = user?.groups
    channels = channels?.filter((channel: any) => channel.type === "Channel").map((channel: any) => channel.id)
    return channels
  }

  const get_user_courier = () => {
    let couriers = user?.groups
    couriers = couriers?.filter((courier: any) => courier.type === "Courier").map((courier: any) => courier.id)
    return couriers
  }

  const onSelectedRangeDate = (range?: IRangeExtend) => {
    setRangeDate(range)
    setPage(INITIAL_PAGE)
  }

  const onChangeSortDate = (sort: SortOrderEnum) => {
    setSort(sort)
    setPage(INITIAL_PAGE)
  }

  const handleChangeCustomFilters = (filter: typeVariantObject | null) => {
    setCustomFilters(filter)
    setPage(INITIAL_PAGE)
  }

  const formatDateOrders = (orders: IOrderShippinggroup[]) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const todayFormat = formatDate(today)
    const yesterDayFormat = formatDate(yesterday)

    orders.forEach((order) => {
      let orderDate = formatDate(order.orderCreationDate)
      let indexAwaiting = order.status.findIndex((status) => status.tag === "AWAITING_STORE_CONFIRMATION")
      if (!isNaN(indexAwaiting)) {
        let { date } = order.status[indexAwaiting]
        orderDate = formatDate(date)
      }
      order.orderCreationDateFormat =
        orderDate === todayFormat ? TODAY : orderDate === yesterDayFormat ? YERTERDAY : orderDate
    })

    return orders
  }

  return {
    search,
    ordersGroup,
    loading,
    setPage,
    maxPage,
    page,
    onSelectedRangeDate,
    setSearch,
    onChangeSortDate,
    handleChangeCustomFilters,
  }
}

export default useGetSgsByStatusWOM
