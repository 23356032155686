import { Fragment, useContext } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import {
  headerStyle,
  buttonIconPopUpStyle,
} from "overrides/theme/entel/base/components/Theme/Layout/Main/styles/Header.style"
import { AuthContext } from "context/context/AuthContext"
import useComponentVisible from "hooks/useComponentVisible"
import useLogin from "hooks/UseLogin"

import logo_entel from "assets/images/logo_entel.svg"
// icons
import user_icon_profile from "assets/icons/entel/user_icon_alt.svg"
import edit_icon from "assets/icons/entel/edit-icon.svg"
import logout_icon from "assets/icons/entel/logout-icon.svg"

export const Header = () => {
  const { logout } = useLogin()
  const authState = useContext(AuthContext)
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const user = authState?.state.user
  const [userMenuPopupRef, userMenuShowPopup, setUserMenuShowPopup] = useComponentVisible(false)
  const signOut = () => logout()

  const ButtonIconPopUp = ({ icon, text, route, onClick }: any) => (
    <Fragment>
      {onClick ? (
        <span className="header__cont-button" onClick={onClick}>
          <img src={icon} alt={text} />
          <p>{text}</p>
        </span>
      ) : (
        <Link className="header__cont-button" to={route}>
          <img src={icon} alt={text} />
          <p>{text}</p>
        </Link>
      )}
      <style jsx>{buttonIconPopUpStyle}</style>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="header__cont-header">
        <div className="header__cont-logo-and-title">
          <a href="/">
            <img src={logo_entel} alt="logo" className="header__client-logo" />
          </a>
          <h3>{displayHeaderRouteName.state}</h3>
        </div>
        <div className="header__cont-notification-and-user">
          <div className="header__dropdown-user">
            <div className="header__user-info header__user-info-in-button">
              <p className="header__name">{user?.name}</p>
              <p className="header__role">{user?.role}</p>
            </div>
            <img
              src={user_icon_profile}
              alt="X"
              className="header__profile-icon"
              onClick={() => {
                setUserMenuShowPopup(!userMenuShowPopup);
                // console.log("Hola", userMenuShowPopup)
              }}
            />
          </div>
        </div>
        <div
          ref={userMenuPopupRef}
          className={!userMenuShowPopup ? "header__dropdown-menu" : "header__dropdown-menu header__box--show"}
        >
          <div className="header__dropdown-menu__body">
            <Link to={`/users/${user?.id}`} className="header__cont-user-info__popup">
              <img src={user_icon_profile} alt="X" className="header__profile-icon-popup" />
              <div className="header__user-info">
                <p className="header__name">{user?.name}</p>
                <p className="header__role">{user?.role}</p>
              </div>
            </Link>
            <div className="header__cont-buttons">
              <ButtonIconPopUp text="Editar Perfil" icon={edit_icon} route={`/users/edit/${user?.id}`} />
              <ButtonIconPopUp text="Cerrar Sesión" icon={logout_icon} onClick={signOut} />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{headerStyle}</style>
    </Fragment>
  )
}

export default Header
