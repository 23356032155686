export const DashboardScreenStyle = `
  .dashboard {    
    display: grid;
    grid-template-columns: 1fr;
  }
  .dashboard__content__children {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 2rem;
    padding: 1.8rem;
    margin: 1.2rem 0;
  }
  .dashboard__content__children--list .dashboard__content__children__items{
    grid-template-columns: 1fr;
  }
  .dashboard__content__children__filters {
    color: var(--color-main-gray);
    display: grid;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: normal;
    grid-template-columns: auto auto;
    justify-content: start;
    margin: 0.5rem 0 0.5rem 0;
  }
  .dashboard__content__children__filters__select {
    margin-left: 0.3rem;
  }
  .dashboard__content__children__filters__select select {
    background: #F0F0F0;
    border: 0;
    border-radius: 0.5rem;
    color: var(--color-main-gray);
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: normal;
    height: auto;
    padding-right: 25px;
  }
  .dashboard__content__children__filters__select svg.icon-arrow {
    color: var(--color-main-gray);
    font-size: 0.8rem;
    right: 5px;
    top: 5px;
  }
  .dashboard__content__children__header {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
  .dashboard__content__children__header__options {
    color: var(--color-main-gray);
    column-gap: 1.5rem;
    display: grid;
    justify-self: end;
    grid-template-columns: repeat(2, auto);
  }
  .dashboard__content__children--block  .dashboard__content__children__header__options__item--block {
    color: var(--color-blue-dark);
  }  
  .dashboard__content__children--list  .dashboard__content__children__header__options__item--list {
    color: var(--color-blue-dark);
  }  
  .dashboard__content__children__header__options__item {
    column-gap: 0.2rem;
    cursor: pointer;
    display: grid;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 0.9rem;
    grid-template-columns: repeat(2, auto);
    justify-items: end;
  }
  .dashboard__content__children__header__options__item--list svg{
    font-size: 1.1rem;
    margin-top: -2px;
  }
  .dashboard__content__children__header__title h3 {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
  }  
  .dashboard__content__children__items {
    display: grid;
    grid-template-columns: 1fr;
  }
  .dashboard__content__summary__item {
    background-color: #FFFFFF;
    border: 1px solid #DDDDE9;
    box-shadow: 0px 6px 24px rgba(207, 195, 172, 0.2);
    border-radius: 12px;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: auto auto 1fr;
    margin-top: 1.5rem;
    justify-items: start;
    padding: 1.4rem 1.2rem;
    transition: all 1s;
  }
  .dashboard__content__summary__item:hover {
    background: linear-gradient(190.98deg, #002eff 1.25%, #4f69e5 94.14%);
  }
  .dashboard__content__children--list  .dashboard__content__children__items {
    gap: initial;    
  }
  .dashboard__content__children--list .dashboard__content__summary__item {
    margin-top: 0.2rem;
    padding: 0.5rem 1.2rem;
  }
  .dashboard__content__children--list  .dashboard__content__summary__item__count > div {
    height: 40px;
    width: 40px;
  }
  .dashboard__content__children--list .dashboard__content__summary__item__count p {
    font-size: 13px;
  }
 
  .dashboard__content__summary__item__icon_wrapper {
    align-items: center;
    background-color: #FFFFFF;
    border: 1.5px solid #002eff;
    display: grid;
    border-radius: 50%;
    justify-items: center;
    height: 40px;
    width: 40px;
  }
  .dashboard__content__summary__item__title {
    margin-top: 0.7rem;
  }
  .dashboard__content__summary__item__title p {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
  .dashboard__content__summary__item__count {
    justify-self: end;
  }
  .dashboard__content__summary__item__count > div{
    align-items: center;
    background: #FFFFFF;
    border: 1.5px solid #002eff;
    border-radius: 50%;
    display: grid;
    height: 65px;
    justify-items: center;
    width: 65px;
  }
  .dashboard__content__summary__item__count p {    
    color: #002eff; 
    font-family: "Montserrat", Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  .dashboard__reports {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 2rem;
    padding: 1.8rem;
    margin: 1.2rem 0;
  }
  .dashboard__reports h3 {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 1rem;
  }
  .dashboard__reports iframe {
    width: 100%;
    height: 320px;
  }

  .dashboard__content__summary__item:hover .dashboard__content__summary__item__title p {
    color: #ffffff;
  }

  .dashboard__content__summary__item:hover .dashboard__content__summary__item__icon_wrapper {
    border-color: #ffffff;
  }

  .dashboard__content__summary__item:hover .dashboard__content__summary__item__count > div {
    border-color: #ffffff;
  }

  .dashboard__content__summary__item:hover .dashboard__content__summary__item__count p {
    color: #000000;
  }

  @media screen and (min-width: 768px) {    
    .dashboard__content__children__items { 
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 12px 35px;
    }
  }
`
