export const buttonMenuStyle = `
  .sidebar__menu--button-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    padding: 0.5em 0;
  }
  .sidebar__menu--button-component:hover {
    opacity: 0.8;
  }
`

export const optionOrderMenuButtonStyle = `
  .sidebar__order-menu--button {
    width: 95%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5em 1em;
    border-bottom: 1px solid #EBEBF4;
    cursor: pointer;
  }
  .sidebar__order-menu--button:hover {
    opacity: 0.7;
  }
  .sidebar__order-menu--button img {
    object-fit: cover;
  }
  .sidebar__order-menu--button p {
    font-size: 1rem;
    font-family: "Montserrat";
    color: #716F87;
    padding-left: 1em;
    text-transform: capitalize;
  }
`

export const sidebarStyle = `
  .sidebar__cont-sidebar {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
  }
  .sidebar__cont-button--hamburger {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0.5em;
  }
  .sidebar__hamburger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 1.5rem;
    cursor: pointer;
    background: #002eff;
    box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.75);
  }
  .sidebar__hamburger:hover {
    opacity: 0.8;
  }
  .sidebar__hamburger_close {
    background: #FFFFFF;
  }
  .sidebar__cont-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0.5em;
  }
  .sidebar__cont--menu--buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.5rem;
    background: linear-gradient(190.98deg, #002eff 1.25%, #4f69e5 94.14%);
    box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.75);
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar__container-orders-popup {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10rem;
    border-radius: 1rem;
    background: #FFFFFF;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    z-index: 100;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar__container-orders-popup:before {
    content: "";
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    background: #FFFFFF;
    position: absolute;
    box-shadow: 3px 0px 0px -3px rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: -10px;
    left: 40%;
  }
  .sidebar__box--show {
    transition: all 0.5s;
    opacity: 1;
    visibility: visible;
  }
  @media screen and (min-width: 768px) {
    .sidebar__cont--menu--buttons {
      width: 30rem;
    }
    .sidebar__container-orders-popup {
      width: 25rem;
      left: 3.5rem;
    }
    .sidebar__box--show {
      transition: all 0.5s;
      opacity: 1;
      visibility: visible;
    }
  }
  @media screen and (min-width: 1280px) {
    .sidebar__cont-sidebar {
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 2rem;
      height: auto;
      z-index: 2;
      left: 1rem;
    }
    .sidebar__cont-button--hamburger {
      /*padding: 2em 0.5em;*/
      padding: 0;
    }
    .sidebar__cont-menu {
      /*padding: 0.5em 1em;*/
      padding: 0;
      position: relative;
      top: 1rem;
    }
    .sidebar__cont--menu--buttons {
      visibility: hidden;
      width: auto;
      height: 30rem;
      flex-direction: column;
    }
    .sidebar__container-orders-popup {
      top: 12.5rem;
      left: 5rem;
    }
    .sidebar__container-orders-popup:before {
      left: -10px;
      top: 15px;
      box-shadow: -2px 0px 2px -2px rgba(0, 0, 0, 0.5);
    }
    .sidebar__box--show {
      transition: all 0.5s;
      opacity: 1;
      visibility: visible;
    }
  }
`
