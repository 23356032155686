import { useContext, useState } from "react"
import { AuthContext } from "context/context/AuthContext"
import { manifestSearch, upLoadFileToManifest } from "services/ListenerServices"
import Logger from "classes/Logger"
import useCancelToken from "hooks/UseCancelToken"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { IRangeExtend } from "interfaces/IContentCelendar"
import { formatDate } from "helpers/FormatDateHelper"
import { convertirABase64 } from "helpers/UtilsHelper"

const UseManifest = () => {

  const [list, setList] = useState([])
  const [initial, setInitial] = useState(true)
  const { newCancelToken } = useCancelToken()
  const [loading, setLoading] = useState(false)
  const [loadingUpLoad, setLoadingUpLoad] = useState(false)
  const { errorHander } = useContext(GlobalContext)

  const {
    state: { user },
  } = useContext(AuthContext)

  const searchManifest = async (rangeDate: IRangeExtend) => {
    try {
      setInitial(false)
      setLoading(true)

      let body: any = {
        from: rangeDate ? `${rangeDate.startDate && formatDate(rangeDate.startDate, "yyyy-MM-dd")}` : null,
        to: rangeDate ? `${rangeDate.endDate && formatDate(rangeDate.endDate, "yyyy-MM-dd")}T23:59:59` : null,
        sources: user?.currentSources && user?.currentSources?.length > 1  ? [user?.role] : [user?.currentSources[0]]
      }

      const {
        data: { code, message },
      } = await manifestSearch(body, newCancelToken())

      if (code === TransactionCodeEnum.ok) {
        setList(message)
        setLoading(false)
      }else{
        Logger.error(code)
        errorHander?.dispatch({ hasError: true, code: code })
        setLoading(false)
      }
    } catch (error: any) {
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
      setLoading(false)
      setInitial(false)
    }
  }

  const getFileByID = async (id: string) => {
    setLoading(true)
    try {
      let body: any = {
        id: id
      }

      const {
        data: { code, message },
      } = await manifestSearch(body, newCancelToken())

      if (code === TransactionCodeEnum.ok) {
        base64ToFileAndDownload(message[0].file, "adjunto")
        setLoading(false)
      }else{
        Logger.error(code)
        errorHander?.dispatch({ hasError: true, code: code })
        setLoading(false)
      }
    } catch (error: any) {
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
      setLoading(false)
    }
  }

  const base64ToFileAndDownload = (base64String: any, fileName: string) => {
    // Verificar si el base64String es válido
    if (!base64String || typeof base64String !== 'string') {
      throw new Error('El string Base64 no es válido.');
    }
  
    // Verificar que el string contenga el prefijo correcto
    const base64Regex = /^data:.+\/.+;base64,/;
    if (!base64Regex.test(base64String)) {
      throw new Error('El string Base64 debe comenzar con un prefijo de tipo de contenido.');
    }
  
    // Extraer la parte base64 del string
    const byteString = window.atob(base64String.split(',')[1]);
    const arrayBuffer = new Uint8Array(byteString.length);
  
    // Convertir el string en bytes
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }

    const fileType = base64String.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9]+);/)![1]
    
    // Crear un blob a partir del array de bytes
    const blob = new Blob([arrayBuffer], { type: fileType });
  
    // Crear un objeto File a partir del blob
    const file = new File([blob], fileName, { type: fileType });
    console.log(file)
  
    // Forzar la descarga del archivo
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  
    // Limpiar el DOM y liberar el objeto URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  const handleUpload = async (id: string, file: any) => {
    setLoadingUpLoad(true)

    let imagenBase64:any
    
    await convertirABase64(file)
      .then((base64) => imagenBase64 = base64 )
      .catch((error) => {
        Logger.error(error)
        setLoadingUpLoad(false)
      })

    try {
      if(imagenBase64) {
        const body: any = {
          id: id,
          file: imagenBase64
        }
        
        const {
          data: { code, message },
        } = await upLoadFileToManifest(body, newCancelToken())

        if (code === TransactionCodeEnum.ok && message === "OK") {
          const listUpdate:any = list.map((item: any) => {
            if(item._id === id) {
              item.hasFile = true
            }
            return item
          })
          
          setList(listUpdate)
          setLoadingUpLoad(false)
        }else{
          Logger.error(code)
          errorHander?.dispatch({ hasError: true, code: code })
          setLoadingUpLoad(false)
        }
      }
    } catch (error: any) {
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
      setLoadingUpLoad(false)
    }
  }

  const handleDeleteFile = async (id: string) => {
    setLoadingUpLoad(true)

    try {
      const body: any = {
        id: id,
        file: null
      }
      
      const {
        data: { code, message },
      } = await upLoadFileToManifest(body, newCancelToken())

      if (code === TransactionCodeEnum.ok && message === "OK") {
        const listUpdate:any = list.map((item: any) => {
          if(item._id === id) {
            item.hasFile = false 
            item.file = undefined
          }
          return item
        })

        setList(listUpdate)
        setLoadingUpLoad(false)
      }else{
        Logger.error(code)
        errorHander?.dispatch({ hasError: true, code: code })
        setLoadingUpLoad(false)
      }
    } catch (error: any) {
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
      setLoadingUpLoad(false)
    }
  }

  return {
    list,
    initial,
    loading,
    getFileByID,
    searchManifest,
    loadingUpLoad,
    handleUpload,
    handleDeleteFile,
  }
}

export default UseManifest
