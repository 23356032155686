import axiosClient from "config/axiosClient"
import configEnv from "config/configEnv"
import { ChannelEnum } from "enums/OrderEnum"
import { IResponseService } from "interfaces/IResponseService"
import { ISeries, IValidateSim } from "interfaces/ISeries"
import { AxiosResponse, CancelToken } from "axios"

const axiosClientListener = axiosClient("LISTENER")

export const valdateSerieItem = async (
  { source, quantity, series, sku }: ISeries,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = axiosClientListener.getClient()

  const body = {
    store_id: source,
    sku,
    quantity,
    series,
  }

  const response = await client.post<IResponseService<string>>(
    `/${configEnv.urlValidateSeries}`,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken,
    },
  )

  return response
}

export const validateSimcardBySerie = async (
  { simcard, channel = ChannelEnum.televentas }: IValidateSim,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = axiosClientListener.getClient()

  const response = await client.post<IResponseService<string>>(
    `/${configEnv.urlValidateSimcard}`,
    {
      simcard,
      channel,
    },
    {
      headers: { "Content-Type": "application/json" },
      cancelToken,
    },
  )

  return response
}

export const updateCourierDelivery = async (
  { shippingGroupId, user, status, scheduleDate }: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = axiosClientListener.getClient()

  const body = {
    solicitudId: shippingGroupId,
    usuario: user,
    estado: status?.retry ? `Intento ${status?.retry}` : status,
    fechaAgendamiento: scheduleDate,
  }

  const response = await client.post<IResponseService<string>>(
    `/${configEnv.urlUpdateDelivery}`,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken,
    },
  )

  return response
}

export const courierConfirmDelivery = async (
  { shippingGroupId, user, status }: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = axiosClientListener.getClient()

  const body = {
    solicitudId: shippingGroupId,
    usuario: user,
    estado: status?.transitOption,
    mensaje: status?.transitOption,
    region:".",
    provincia: ".",
    comuna: ".",
    calle: ".",
    numero:".",
    nombreReceptor: ".",
    apellidoReceptor : ".",
    rutReceptor : ".",
    telefonoReceptor : "."
  }

  const response = await client.post<IResponseService<string>>(
    `/${configEnv.urlConfirmDelivery}`,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken,
    },
  )

  return response
}

export const generateSgDocuments = async (
  { orderId, type, shippingGroupId }: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientListener.getClient()

  const response = await client.post<IResponseService<any>>(
    `/${configEnv.urlGenerateSgDocuments}`,
    {
      orderId,
      type,
      shippingGroupId,
    },
    {
      headers: { "Content-Type": "application/json" },
      cancelToken,
    },
  )

  return response
}


export const notificationPickupCode = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientListener.getClient()

  const url = `${configEnv.urlNotificationPickupCode}`

  const response = await client.post<IResponseService<any>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const deliveryCodeGenerate = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientListener.getClient()

  const url = `${configEnv.urlDeliveryCodeGenerate}`

  const response = await client.post<IResponseService<any>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const deliveryCodeValidate = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientListener.getClient()

  const url = `${configEnv.urlDeliveryCodeValidate}`

  const response = await client.post<IResponseService<any>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const manifestGenerate = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientListener.getClient()

  const url = `${configEnv.urlManifestGenerate}`

  const response = await client.post<IResponseService<any>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const manifestSearch = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientListener.getClient()

  const url = `${configEnv.urlManifestSearch}`

  const response = await client.post<IResponseService<any>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const upLoadFileToManifest = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = axiosClientListener.getClient()

  const url = `${configEnv.urlManifestUpdate}`

  const response = await client.post<IResponseService<any>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}