import { useEffect, useContext } from "react"
import { AuthContext } from "context/context/AuthContext"
import { useTranslation } from "react-i18next"
import { OrderDetailModalActionStyle, FormRescheduleActionStyle } from "overrides/theme/entel/base/pages/DetailOrderScreens/styles/OrderDetailModalAction.style"
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import useUpdateSGCustom from "overrides/theme/entel/base/hooks/useUpdateSGCustom"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"

const FormRescheduleAction = ({ close, action }: any) => {
  const { t } = useTranslation()
  const { state: { user } } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    reset
  } = useForm<any>()
  const { shippingGroupId, id }: any = useParams()
  const { loading, onUpdateSGActionCustom } = useUpdateSGCustom()

  useEffect(() => {
    reset({
      status: "reschedule",
      reference: `${id}-${shippingGroupId}`,
      title: "PEDIDO REAGENDADO",
      signature: user?.id
    })
    // eslint-disable-next-line
  }, [user, shippingGroupId, id, action])

  const on_submit = (data: any) => {
    onUpdateSGActionCustom(data, action).then(() => close())
  }

  return (
    <>
      {loading && <Loading defaultOpened={loading} />}
      <form className="form_reschedule_action__main" onSubmit={handleSubmit(on_submit)}>
        <input {...register("status")} type="hidden" />
        <input {...register("reference")} type="hidden" />
        <input {...register("title")} type="hidden" />
        <input {...register("signature")} type="hidden" />
        <input
          {...register("tracking_id")}
          type="text"
          className="form_reschedule_action__input_form"
          placeholder="Tracking (ID)"
        />
        <textarea
          {...register("checkout_comment")}
          className="form_reschedule_action__input_form"
          placeholder="Comentario del conductor"
        />
        <textarea
          {...register("route.comment")}
          className="form_reschedule_action__input_form"
          placeholder="Comentario adicional"
        />
        <div className="order_detail_modal_action__cont_buttons">
          <button
            type="button"
            className="order_detail_modal_action__btn_no"
            onClick={close}
          >
            No
          </button>
          <button
            className="order_detail_modal_action__btn_yes"
            type="submit"
          >
            {`Si, ${t(`${action.action}`)} Órden`}
          </button>
        </div>
      </form>
      <style jsx>{FormRescheduleActionStyle}</style>
    </>)
}

const OrderDetailModalAction = ({ action, confirmAction, close }: any) => {
  const { t } = useTranslation()
  const isReschedule = action.action === "reschedule"

  return (
    <>
      <div className="order_detail_modal_action__root">
        <div className="order_detail_modal_action__main_cont">
          <div className="order_detail_modal_action__cont_title">
            <p className="order_detail_modal_action__cont_title_main">
              ¿Desea <span>{t(action.action)}</span> esta órden?
            </p>
            <p className="order_detail_modal_action__cont_title_descr">
              Esta acción NO se puede deshacer
            </p>
          </div>
          <div className="order_detail_modal_action__cont_body">
            {isReschedule && <FormRescheduleAction action={action} close={close} />}
          </div>
          {!isReschedule && <div className="order_detail_modal_action__cont_buttons">
            <button
              className="order_detail_modal_action__btn_no"
              onClick={close}
            >
              No
            </button>
            <button
              className="order_detail_modal_action__btn_yes"
              onClick={() => {
                confirmAction();
                close()
              }}
            >
              {`Si, ${t(`${action.action}`)} Órden`}
            </button>
          </div>}
        </div>
      </div>
      <style jsx>{OrderDetailModalActionStyle}</style>
    </>
  )
}

export default OrderDetailModalAction