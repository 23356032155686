import { Fragment, FunctionComponent, useState, useEffect, useRef, useContext, useCallback } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { RouteComponentProps, useHistory, useParams } from "react-router-dom"
import IPage from "interfaces/IPage"
import Paginate from "overrides/theme/entel/base/components/Commons/Pagination/Paginate"
import ErrorHandled from "overrides/theme/entel/base/components/Commons/Error/ErrorHandler"
import SortOrder from "overrides/theme/entel/base/components/Core/Order/Sort/SortOrder"
import SearchForm from "overrides/theme/entel/base/components/SearchForm/SearchForm"
import OrderList from "overrides/theme/entel/base/components/Core/Order/OrderList"
import { useGetSgsByStatus } from "overrides/theme/entel/base/hooks"
import {orderScreenStyle} from "overrides/theme/entel/base/pages/OrderScreens/OrderScreen.style"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import useOrderList from "overrides/theme/entel/base/components/Core/Order/hooks/UseChekedOrderList"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import { STORAGE_SORT_ORDER } from "helpers/constHelper"
import { SortOrderEnum } from "enums/OrderEnum"
import useLocalStorage from "hooks/useLocalStorage"
import { useTranslation } from "react-i18next"
import { IoGrid, IoList } from "react-icons/io5"
import { useEnv } from "hooks"

const OrderScreen: FunctionComponent<IPage & RouteComponentProps<any>> = (props: any) => {
  // TODO: refactor
  const [sort, set_sort] = useLocalStorage(STORAGE_SORT_ORDER, SortOrderEnum.desc)
  const { t } = useTranslation()
  const { permissions } = useEnv()
  const [view, set_view] = useState("grid")
  const { query, optionFilter, shippingtype, type }: any = useParams()
  const history = useHistory()
  const [data, set_data] = useState(null)
  const [pages, set_pages] = useState(0)
  const [page, set_page] = useState(0)
  const [loading, set_loading] = useState(false)
  const refElemOrders = useRef<HTMLDivElement>(null)
  const { displayHeaderRouteName } = useContext(GlobalContext)

  const { sg_group_by, get_sg_list_grouped_by_date } = useGetSgsByStatus()

  const {
    checkAllOrders,
    setOrdersSGChecked,
    ordersSGChecked,
    selectAll,
    canSelectMultiple,
    statusSG,
    disabledUpdateSG,
    loadingUpdateSG,
    updateShippingGroupAction,
    conditionChecked,
    labelGroupAction,
    setOrdersSGCheckedToPrint,
  } = useOrderList(data)

  useEffect(() => {
    const statusses: any = translatesObj
    let parsedType = type.toUpperCase()
    displayHeaderRouteName.dispatch(statusses[parsedType].subTitle)
    //eslint-disable-next-line
  }, [type])

  useEffect(() => {
    const post_request = async () => {
      set_loading(true)
      const res: any = await get_sg_list_grouped_by_date(page, sort)
      const rest_data: any = sg_group_by(res.data.message.records)
      set_pages(res.data.message.pages)
      set_data(rest_data)
      set_loading(false)
    }

    post_request()
    //eslint-disable-next-line
  }, [query, optionFilter, sort, page])

  const event_set_page = (pageSeleted: number) => {
    set_page(pageSeleted)
  }

  const event_set_search = (e: any) => {
    if (!e.query) history.push(`/order/${shippingtype}/${type}`)
    if (!!e.query) history.push(`/search/order/${shippingtype}/${type}/${e.optionFilter}/${e.query}`)
  }

  const event_sort_date = (e: any) => {
    set_sort(e)
    set_page(0)
  }

  const event_set_view = (type_view: any) => {
    if (view !== type_view) set_view(type_view)
  }

  const handleClickSelectAll = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = ev.target
    checkAllOrders(checked)
  }

  const handleClickUpdateOrders = () => {
    updateShippingGroupAction()
  }

  const handleClickCheckOrder = useCallback(
    (ev: any, infoSgToPrint: any) => {
      const { id, checked } = ev.target
      if (!checked) {
        setOrdersSGChecked((prevOrderSG) => {
          return prevOrderSG.filter((sg) => sg !== id)
        })
        setOrdersSGCheckedToPrint((prevValue) => prevValue.filter((sg) => sg.id !== id))
        return
      }
      setOrdersSGChecked((prevOrderSG) => [...prevOrderSG, id])
      setOrdersSGCheckedToPrint((prevValue) => [...prevValue, infoSgToPrint])
    },
    [setOrdersSGChecked, setOrdersSGCheckedToPrint],
  )

  return (
    <Fragment>
      <div className="order-screen__container" ref={refElemOrders}>
      {permissions.get_permission("global", "block-search-list") &&<SearchForm onClickSearch={event_set_search} isLoading={loading} backButton={true} />}
        <section className={canSelectMultiple ? "order-screen__section-filters order-screen__section-filters_check_enabled" : "order-screen__section-filters"}>
          {canSelectMultiple && <div className="order-screen__content-dispatch-action">
            <label className="order-screen__check-container">
              Seleccionar todo
              <input
                type="checkbox"
                id="selectAll"
                name="selectAll"
                onChange={handleClickSelectAll}
                checked={selectAll}
              />
              <span className="order-screen__check-checkmark"></span>
            </label>
            <button
              disabled={disabledUpdateSG || loadingUpdateSG}
              onClick={handleClickUpdateOrders}
              className="order-screen__button-dispatch-action"
            >
              <p>{loadingUpdateSG ? "Espere.." : labelGroupAction}</p>
            </button>
            {loadingUpdateSG && <Loading defaultOpened={loadingUpdateSG} />}
          </div>}
          <div className="order-screen__container__header__options">
            <div className="order-screen__content__header__options">
              <div className="order-screen__content__header__options__item" onClick={() => event_set_view("grid")}>
                <p>{t("Vista Resumen")}</p> <IoGrid />
              </div>
              <div className="order-screen__content__header__options__item" onClick={() => event_set_view("list")}>
                <p>{t("Vista Lista")}</p> <IoList />
              </div>
            </div>
            <SortOrder handleChangeSort={event_sort_date} disabled={loading} />
          </div>
        </section>

        {loading ? (
          <Loading defaultOpened={loading} />
        ) : (
          <>
            {Object.keys(data || {}).length ? (
              <div>
                <div className="order-screen__order-list-container">
                  <OrderList
                    ordersGroup={data}
                    currentDisplayList={view}
                    handleClickCheckOrder={handleClickCheckOrder}
                    canSelectMultiple={canSelectMultiple}
                    conditionChecked={conditionChecked}
                    ordersSGChecked={ordersSGChecked}
                    statusSG={statusSG}
                  />
                </div>
                {pages > 1 && (
                  <div className="order-screen__paginate-container">
                    <Paginate
                      pageCount={pages}
                      onChangePage={event_set_page}
                      elementFocus={refElemOrders}
                      pageInit={page}
                    />
                  </div>
                )}
              </div>
            ) : (
              <ErrorHandled orders={data} message="Sin resultados para esta busqueda" />
            )}
          </>
        )}
      </div>
      <style jsx>{orderScreenStyle}</style>
    </Fragment>
  )
}

export default OrderScreen
